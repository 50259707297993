import React, { useContext } from 'react'

import './style.scss'

import { GatsbyImage } from 'gatsby-plugin-image'
import FSLightBoxContext from '../../context/FSLightBoxContext'

function Gallery(props) {
  const lightBoxContext = useContext(FSLightBoxContext)

  const slides = props.gallery

  function thumbClicked() {

    let timer = setTimeout(() => {
    lightBoxContext.setSources(() => null)

    let lightboxSlidesArray = []

    slides.forEach((slide) => {
      lightboxSlidesArray.push( 
        <div>
          <GatsbyImage image={slide?.localFile?.childImageSharp?.gatsbyImageData} alt={"parallax-img"}/>
        </div>
      )
    })

    lightBoxContext.setSources(() => lightboxSlidesArray)

      lightBoxContext.setToggler((toggle) => !toggle)
    }, 50)

    return () => clearTimeout(timer)
  }

  return (
    <GatsbyImage image={slides?.[0]?.localFile?.childImageSharp?.gatsbyImageData} alt={"parallax-img"} onClick={() => thumbClicked()} />
  )
}


export default Gallery