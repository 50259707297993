import React, { useEffect, useState } from 'react'
import './style.scss'
import Seo from '../../components/seo'

import Footer from '../../components/Footer'
import { Helmet } from "react-helmet"
import ReactHtmlParser from 'react-html-parser'

import { graphql, Link } from 'gatsby'
import Pagination from './Pagination'
import Intro from '../../animations/Intro'

import Projects from '../../components/Content/ACF/Custom/Projects'
import Content from '../../components/Content'

function Posts({ data, pageContext }) {

  const heroData = {
    pageBuilder: {
      sections: [
        {
          anchor: null,
          classes: "hero t",
          block: [
            {
              anchor: null,
              bgOverlay: 0.4,
              classes: "hero x1 bg",
              fieldGroupName: "page_Pagebuilder_Sections_Content_Block_Image",
              img: {
                url: 'https://stalowaraczka.unixstorm.org/wp-content/uploads/2022/03/pexels-pavel-chernonogov-2381463.jpg'
              },
              imgResponsive: null,
              lightbox: null,
              parallaxFrom: -20,
              parallaxTo: 20,
              style: "",
              wysiwyg: "<h1>Realizacje</h1>\n",
            }
          ],
        }
      ]
    }
  }
  const currentPage = pageContext?.currentPage
  const postsPerPage = pageContext?.postsPerPage //parseInt(.postsPerPage)
  const skip = pageContext?.skip //postsPerPage * (parseInt(currentPage) - 1)
  const skap = skip + postsPerPage
  const maxPages = pageContext?.numPages //Math.ceil(data.allContentfulBlogPost.edges.length / postsPerPage)
  const postItems = data?.allWpPost?.edges?.slice(skip, skap)
  const postSlug = pageContext?.slug


  useEffect(() => {

    if (document.querySelector('.posts')) {
      window.setTimeout(() => window.scrollTo({
        top: window.pageYOffset + document.querySelector('.posts')?.getBoundingClientRect().top,
        behavior: 'smooth'
      }), 500)
    }
  }, [])

  // console.log(Math.ceil(data.allWpPost.edges.filter(edge => edge?.node?.categories?.nodes?.[0]?.name?.toLowerCase() === 'Drzwi').length / postsPerPage))
  return (
    <>
      <main className={`c0 main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug} lang-${data.wpGeneralSettings?.generalSettings?.language}`}>

        {data.wpPage?.yoast_head ?
          <Helmet>{ReactHtmlParser(data.wpPage?.yoast_head)}</Helmet>
          :
          <Seo title={pageContext?.metaTitle} description={pageContext?.metaDescription} siteTitle={data.wpGeneralSettings?.generalSettings?.title} />
        }
        <Content wpPage={heroData}></Content>
        <section id="posts" className="posts flex padd-top">
          <div className='padd-half  news-filter-bar padd padd-left-off uppercase t x0'>
            <Link to="/realizacje/" className={`${postSlug === 'realizacje' ? 'active' : ''}`}> Wszystko </Link>
            /
            <Link to="/realizacje/drzwi" className={`${postSlug === 'realizacje/drzwi' ? 'active' : ''}`}> Drzwi </Link>
            /
            <Link to="/realizacje/scianki" className={`${postSlug === 'realizacje/scianki' ? 'active' : ''}`}> Ścianki </Link>
            /
            <Link to="/realizacje/balustrady" className={`${postSlug === 'realizacje/balustrady' ? 'active' : ''}`}> Balustrady </Link>
            /
            <Link to="/realizacje/ogrodzenia" className={`${postSlug === 'realizacje/ogrodzenia' ? 'active' : ''}`}> Ogrodzenia </Link>
            /
            <Link to="/realizacje/meble" className={`${postSlug === 'realizacje/meble' ? 'active' : ''}`}> Meble </Link>
            /
            <Link to="/realizacje/tarasy" className={`${postSlug === 'realizacje/tarasy' ? 'active' : ''}`}> Tarasy </Link>

            {/* / */}
            {/* <Link to="/media-guide" className={`${postSlug === 'media-guide' ? 'active' : ''}`}> Inne </Link>   */}
          </div>
          <Intro key={"posts-page-" + currentPage} switch={currentPage} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="posts-grid">
            <div className="posts-grid">
              <Projects gallery={postItems} />
            </div>
          </Intro>
        </section>

        <Pagination currentPage={currentPage} slug={postSlug} maxPages={maxPages} />

        <Footer />

      </main>
    </>
  )
}

export default Posts

export const query = graphql`
  query PostsQuery($category: String!) {
    ...wpNaviPrimary
    ...wpGeneralSettings
    allWpPost(sort: {order: DESC, fields: date}, filter: {categories: {nodes: {elemMatch: {name: {regex: $category}}}}})  {
      edges {
        node {
          id
          title
          slug
          work {
            gallery {
            slug
            title
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`
