import React from 'react'
import Intro from '../../../../../animations/Intro'
import './style.scss'
import AcgWysiwyg from '../../WYSIWYG'
import GalleryApi from '../../../../GalleryAPI'
import { useInView } from 'react-intersection-observer'

function CustomBlock({ gallery }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const gridItem = gallery?.map((image, i) => {
    let item = image?.node?.work
    const wysiwyg = `<h6>${image?.node?.title}</h6>`

    // const random = Math.floor(Math.random() * gallery.length) + 1;
    return (
      <div key={i} className='grid-item'>
        <Intro visible={ioInView} in={{ fade: 700, bg: 700 }} delayIn={100 * i} mounted={true} stay={true} className="">
          <GalleryApi gallery={item.gallery} />
          <div className='title-container'>
            {wysiwyg ?
              <div className='title'>
                <AcgWysiwyg wysiwyg={wysiwyg} />
              </div>
              : null}
          </div>
        </Intro>
      </div>
    );
  })

  return (
    <div ref={io} className="grid-mansory">
      {gridItem}
    </div>
  )
}

export default CustomBlock